import { useTitle } from '@shared/hooks'
import { useParams } from 'react-router'

import { useSkuApi } from '../api'

export const useSkuTitle = () => {
  const { id: skuId } = useParams()
  const sku = useSkuApi(skuId)

  return useTitle(sku)
}
