import { ProgressWithStatus } from '@entities/catalog'
import { MESSAGE } from '@shared/config'

import { FilesCardStatus } from './files-card-status'
import { FILE_STATUS, type FilesCardDownloadInfoProps } from './types'

const FilesCardDownloadInfo: ReactFc<FilesCardDownloadInfoProps> = ({ status, progress }) => {
  if (status === FILE_STATUS.COMPLETED_SUCCESS) {
    return <FilesCardStatus type="checkInCircleFilled" color="success" text={MESSAGE.DONE} />
  }

  if (status === FILE_STATUS.COMPLETED_ERROR) {
    return <FilesCardStatus type="warning" color="danger" text={MESSAGE.ERROR} />
  }

  return <ProgressWithStatus {...{ progress, status }} />
}

export { FilesCardDownloadInfo }
