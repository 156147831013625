import { useCategoryApi } from '@entities/categories'
import { useMeta, useTitle } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { CategoryTabs } from '@widgets/settings'
import { useParams } from 'react-router'

const CategoryPage: ReactFc = () => {
  const { id: categoryIdParam = '' } = useParams()
  const categoryId = parseInt(categoryIdParam)
  const category = useCategoryApi(categoryId)
  useMeta({ title: useTitle(category) })

  return (
    <Layout
      pageBodyProps={{
        paddingSize: 'm',
      }}
    >
      <CategoryTabs />
    </Layout>
  )
}

export { CategoryPage }
