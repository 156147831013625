import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = () => {
  return {
    skeletonCard: css`
      inline-size: 100% !important;
      block-size: 90px !important;
    `,
  }
}
