import { EuiButtonIcon, EuiToolTip } from '@elastic/eui'
import { useSkuApi } from '@entities/catalog'
import { useStyles } from '@shared/ui/styles'
import { useParams } from 'react-router'

import { getStyles } from './styles'
import { type SkuToolbarButtonProps } from './types'

export const SkuToolbarButton: ReactFc<SkuToolbarButtonProps> = (props) => {
  const { content, isDisabled = false } = props
  const { id: skuId } = useParams()
  const { isLoading } = useSkuApi(skuId)
  const styles = useStyles(getStyles)

  return (
    <EuiToolTip content={content}>
      <EuiButtonIcon
        aria-label={content}
        size="xs"
        color="text"
        display="base"
        isDisabled={isLoading || isDisabled}
        css={styles.button}
        {...props}
      />
    </EuiToolTip>
  )
}
