import { EuiForm } from '@elastic/eui'
import { ACTION, ELEMENTS_L_WIDTH } from '@shared/config'
import { SelectableList } from '@shared/ui'
import { Modal } from '@shared/ui/modal'
import { FormProvider, useForm } from 'react-hook-form'

import { useExportSkus } from '../model'
import { type AddSkuProps } from './types'

const ExportSku: ReactFc<AddSkuProps> = ({ isOpen, onClose }) => {
  const methods = useForm()

  const { columnsList, isColumnsLoading, handleSelectChange } = useExportSkus()

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.EXPORT}
        onClose={onClose}
        // TODO: при реализоации функции экспорта, заменю onSubmit на актуальный
        onSubmit={() => {}}
        isOpen={isOpen}
        isSubmitting={false}
        submitButtonText={ACTION.START_EXPORT}
        maxWidth={ELEMENTS_L_WIDTH}
      >
        <EuiForm component="form" fullWidth>
          <SelectableList
            itemsList={columnsList}
            onChange={handleSelectChange}
            isLoading={isColumnsLoading}
          />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { ExportSku }
