import { EuiButtonIcon, EuiPageSidebar, EuiPanel, EuiSpacer } from '@elastic/eui'
import { useUserSettingsStore } from '@entities/user'
import {
  LABEL,
  ROUTES,
  SIDEBAR_CLOSED_LOGO_WIDTH,
  SIDEBAR_CLOSED_MIN_WIDTH,
  SIDEBAR_OPEN_LOGO_WIDTH,
  SIDEBAR_OPEN_MIN_WIDTH,
} from '@shared/config'
import { Logo } from '@shared/ui/icons'
import { positionAbsolute, positionRelative, textCenter, useStyles } from '@shared/ui/styles'
import { Link } from 'react-router'

import { SidebarNav } from './sidebar-nav'
import { getStyles } from './styles'

// TODO: Сделать через API, чтоб приходили только доступные пункты меню
export const Sidebar: ReactFc = () => {
  const isSidebarOpen = useUserSettingsStore.use.isSidebarOpen()
  const toggleSidebarOpen = useUserSettingsStore.use.toggleSidebarOpen()

  const styles = useStyles(getStyles)

  if (isSidebarOpen) {
    return (
      <EuiPageSidebar
        paddingSize="l"
        sticky
        minWidth={SIDEBAR_OPEN_MIN_WIDTH}
        css={[styles.sidebar, textCenter]}
      >
        <EuiPanel paddingSize="none" hasShadow={false} color="transparent" css={positionRelative}>
          <Link to="/">
            <Logo size={SIDEBAR_OPEN_LOGO_WIDTH} variant="full" />
          </Link>
          <EuiSpacer />
          <EuiButtonIcon
            aria-label="Скрыть навигацию"
            iconType="menuLeft"
            color="text"
            css={[positionAbsolute, styles.toggle]}
            onClick={toggleSidebarOpen}
          />
          <SidebarNav />
        </EuiPanel>
      </EuiPageSidebar>
    )
  }

  return (
    <EuiPageSidebar minWidth={SIDEBAR_CLOSED_MIN_WIDTH} sticky css={[styles.sidebar, textCenter]}>
      <EuiSpacer size="l" />
      <Link to="/">
        <Logo size={SIDEBAR_CLOSED_LOGO_WIDTH} />
      </Link>
      <EuiSpacer />
      <EuiButtonIcon
        aria-label="Открыть навигацию"
        iconType="menuRight"
        color="text"
        onClick={toggleSidebarOpen}
      />
      <EuiSpacer size="s" />
      <Link to={ROUTES.CATALOG.$path()}>
        <EuiButtonIcon aria-label="Все товары" iconType="package" color="text" />
      </Link>
      <EuiSpacer size="xs" />
      <Link to={ROUTES.DAM.$path()}>
        <EuiButtonIcon aria-label="DAM" iconType="documents" color="text" />
      </Link>
      <EuiSpacer size="xs" />
      <Link to={ROUTES.SETTINGS.$path()}>
        <EuiButtonIcon
          aria-label={LABEL.SETTINGS}
          iconType="gear"
          color="text"
          onClick={toggleSidebarOpen}
        />
      </Link>
    </EuiPageSidebar>
  )
}
