import { EuiPanel } from '@elastic/eui'
import { useUserApi } from '@entities/users'
import { ACTION } from '@shared/config'
import { InfoPageCategory } from '@shared/ui'
import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router'

import { type UserInfoProps } from './types'
import { UserInfoRows } from './user-info-rows'

const UserInfo: ReactFc<UserInfoProps> = ({ methods, isUserSubmitting }) => {
  const { id } = useParams()
  const userId = parseInt(id!)
  const { data: user, isLoading: isUserLoading } = useUserApi(userId)

  // TODO: реализовать обработку кейса, когда валидатор не получен
  if (!user) {
    if (!isUserLoading) {
      console.error('Не удалось получить валидатор.')
    }

    return null
  }

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <FormProvider {...methods}>
        <InfoPageCategory id={userId} categoryName={ACTION.ALL_FIELDS}>
          <UserInfoRows id={userId} isSubmitting={isUserSubmitting} />
        </InfoPageCategory>
      </FormProvider>
    </EuiPanel>
  )
}

export { UserInfo }
