import { $api } from '@shared/api'

import { type ExportedFilesListQuery } from '../types'

const useFilesListApi = (args: ExportedFilesListQuery = {}) => {
  return $api.useQuery('get', '/exported_files/', {
    params: {
      query: {
        size: 0,
        ...args,
      },
    },
    refetchInterval: 30000,
  })
}

export { useFilesListApi }
