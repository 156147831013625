import { ROUTES } from '@shared/config'
import { Link } from 'react-router'

import { type UsersTableRow } from '../types'

const renderUserName: RenderColumn<UsersTableRow, 'name'> = (value, { id }) => {
  return <Link to={ROUTES.SETTINGS.USERS.USER.$buildPath({ params: { id } })}>{value}</Link>
}

export { renderUserName }
