import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { type SelectableListActionsProps } from '../types'

const SelectableListActions: ReactFc<SelectableListActionsProps> = ({ actions }) => {
  return (
    <EuiFlexItem grow={1}>
      <EuiFlexGroup direction="column" justifyContent="center" alignItems="center" gutterSize="m">
        {actions.map((action) => {
          const { isDisabled, ariaLabel, iconType, onClick } = action

          return (
            <EuiFlexItem grow={false} key={iconType}>
              <EuiButtonIcon
                disabled={isDisabled}
                iconType={iconType}
                aria-label={ariaLabel}
                onClick={onClick}
              />
            </EuiFlexItem>
          )
        })}
      </EuiFlexGroup>
    </EuiFlexItem>
  )
}

export { SelectableListActions }
