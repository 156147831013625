import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer } from '@elastic/eui'

import { FilesCardDownloadInfo } from './files-card-download-info'
import { FilesCardName } from './files-card-name'
import { FilesCardSubInfo } from './files-card-sub-info'
import { type ExportedFile } from './types'

const ExportFilesCard: ReactFc<ExportedFile> = ({
  created_at,
  updated_at,
  username,
  status,
  progress,
  file_url,
  file_name,
}) => {
  return (
    <EuiPanel hasShadow={false}>
      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
        <EuiFlexItem grow={8}>
          <FilesCardName file_url={file_url} status={status} file_name={file_name} />
        </EuiFlexItem>

        <EuiFlexItem grow={1}>
          <FilesCardDownloadInfo status={status} progress={progress} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="m" />

      <FilesCardSubInfo created_at={created_at} updated_at={updated_at} username={username} />
    </EuiPanel>
  )
}

export { ExportFilesCard }
