import { $api, API_QUERY_KEY, queryClient } from '@shared/api'
import { useParams } from 'react-router'

export const useAddAttributeToCategory = () => {
  const { id: categoryIdParam = '' } = useParams()
  const categoryId = parseInt(categoryIdParam)

  const add = $api.useMutation('patch', '/attributes/{id}/')

  const addToCategory = (id: number) => {
    return () => {
      return add.mutate(
        {
          params: {
            path: {
              id,
            },
          },
          body: {
            category: categoryId,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: API_QUERY_KEY.ATTRIBUTE_LIST,
            })

            add.reset()
          },
        },
      )
    }
  }

  const isProcessed = (id: number) => {
    return add.variables?.params.path.id === id
  }

  const isPending = add.isPending

  return {
    isPending,
    addToCategory,
    isProcessed,
  }
}
