import { EuiButton, EuiEmptyPrompt, EuiPageSection } from '@elastic/eui'
import { ACTION, ERROR, PAGE_TITLES, ROUTES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { minHViewport } from '@shared/ui/styles'
import { Link } from 'react-router'

export const PermissionErrorPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.PERMISSION_ERROR.title })

  return (
    <Layout>
      <EuiPageSection alignment="center" grow css={minHViewport}>
        <EuiEmptyPrompt
          title={<h2>{PAGE_TITLES.PERMISSION_ERROR.title}</h2>}
          body={<p>{ERROR.PERMISSION}</p>}
          actions={
            <Link to={ROUTES.LOGOUT.$path()}>
              <EuiButton fill>{ACTION.SWITCH_USER}</EuiButton>
            </Link>
          }
        />
      </EuiPageSection>
    </Layout>
  )
}
