import { LABEL } from '@shared/config'
import lodash from 'lodash'
import { type ChangeEvent, useState } from 'react'

import { type HandleMoveArgs, type SelectableListItem, SIDE } from '../types'
import { type UseSelectableListProps } from './types'

const useSelectableList = ({ itemsList, onChange, isLoading = false }: UseSelectableListProps) => {
  const [left, setLeft] = useState<SelectableListItem[]>([])
  const [right, setRight] = useState<SelectableListItem[]>(lodash.cloneDeep(itemsList))

  const handledToggle = (side: SIDE) => {
    return ({ target }: ChangeEvent<HTMLInputElement>) => {
      const newValue = [...(side === SIDE.LEFT ? left : right)]
      const setList = side === SIDE.LEFT ? setLeft : setRight

      const { name, checked } = target

      const index = newValue.findIndex((item) => {
        return item.name === name
      })

      newValue[index].isChecked = checked

      setList(newValue)
    }
  }

  const hasChecked = (side: SIDE) => {
    const itemsList = side === SIDE.LEFT ? left : right

    const indexOfChecked = itemsList.findIndex(({ isChecked }) => {
      return isChecked
    })

    return indexOfChecked > -1
  }

  const handleMove = ({ doesMoveToChecked = false, doesMoveChecked = false }: HandleMoveArgs) => {
    const listMoveFrom = doesMoveToChecked ? left : right
    const listMoveTo = doesMoveToChecked ? right : left

    const setListMoveFrom = doesMoveToChecked ? setLeft : setRight
    const setListMoveTo = doesMoveToChecked ? setRight : setLeft

    if (doesMoveChecked) {
      return () => {
        const checkedItems = listMoveFrom
          .filter(({ isChecked }) => {
            return isChecked
          })
          .map((item) => {
            return { ...item, isChecked: false }
          })

        const newValueMoveFrom = listMoveFrom.filter(({ isChecked }) => {
          return !isChecked
        })

        const newValueMoveto = [...listMoveTo, ...checkedItems].sort((prev, next) => {
          return prev.label.localeCompare(next.label)
        })

        setListMoveTo(newValueMoveto)
        setListMoveFrom(newValueMoveFrom)
        onChange(doesMoveToChecked ? newValueMoveto : newValueMoveFrom)
      }
    }

    return () => {
      setListMoveTo(lodash.cloneDeep(itemsList))
      setListMoveFrom([])
      onChange(doesMoveToChecked ? itemsList : [])
    }
  }

  const actions = [
    {
      isDisabled: !left.length || isLoading,
      iconType: 'doubleArrowRight',
      ariaLabel: LABEL.SELECT_ALL_COLUMNS,
      onClick: handleMove({ doesMoveToChecked: true }),
    },
    {
      isDisabled: !hasChecked(SIDE.LEFT) || isLoading,
      iconType: 'arrowRight',
      ariaLabel: LABEL.SELECT_CHECKED_COLUMNS,
      onClick: handleMove({ doesMoveToChecked: true, doesMoveChecked: true }),
    },
    {
      isDisabled: !hasChecked(SIDE.RIGHT) || isLoading,
      iconType: 'arrowLeft',
      ariaLabel: LABEL.REMOVE_CHECKED_COLUMNS,
      onClick: handleMove({ doesMoveChecked: true }),
    },
    {
      isDisabled: !right.length || isLoading,
      iconType: 'doubleArrowLeft',
      ariaLabel: LABEL.REMOVE_ALL_COLUMNS,
      onClick: handleMove({}),
    },
  ]

  return { left, right, actions, handledToggle }
}

export { useSelectableList }
