import { useStyles } from '@shared/ui/styles'
import { NavLink } from 'react-router'

import { getStyles } from './styles'
import { type RenderLinkProps } from './types'

export const RenderLink = ({ href = '/', className, children }: RenderLinkProps) => {
  const styles = useStyles(getStyles)

  return (
    <NavLink to={href} className={className} css={styles.link}>
      {children}
    </NavLink>
  )
}
