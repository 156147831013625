import { useAttributesData } from '@shared/hooks'
import { type SelectableListItem } from '@shared/ui'
import { useRef } from 'react'

const useExportSkus = () => {
  const { isLoading: isAttributesLoading, attributesList } = useAttributesData()

  const columnsList = attributesList.map(({ id, name }) => {
    return {
      name: id.toString(),
      label: name,
      isChecked: false,
    }
  })

  const selectedColumns = useRef<string[]>([])

  const onChange = (selectedItems: SelectableListItem[]) => {
    selectedColumns.current = selectedItems.map(({ name }) => {
      return name
    })
  }

  return {
    columnsList,
    isColumnsLoading: isAttributesLoading,
    selectedColumns,
    handleSelectChange: onChange,
  }
}

export { useExportSkus }
