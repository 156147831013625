import { useAttributeApi, useUpdateAttributeApi } from '@entities/attributes'
import { type ApiAttribute } from '@shared/api/types'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

const useUpadateAttribute = () => {
  const { id: attributeId } = useParams()
  const { data: attribute } = useAttributeApi(attributeId!)
  const methods = useForm<ApiAttribute>({ values: attribute })

  const {
    mutation: { mutateAsync },
    isSubmitting: isAttributeSubmitting,
  } = useUpdateAttributeApi(attributeId!)

  const {
    formState: { isDirty },
  } = methods

  const updateAttribute = methods.handleSubmit(async (data: ApiAttribute) => {
    const response = await mutateAsync(data)

    methods.reset(response)
  })

  return { isSaveButtonActive: isDirty, updateAttribute, isAttributeSubmitting, methods }
}

export { useUpadateAttribute }
