import '@app/app.css'

import { EuiThemeProvider } from '@elastic/eui'
import { queryClient } from '@shared/api'
import { baseTheme } from '@shared/config'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router'

import { router } from './router'

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <EuiThemeProvider modify={baseTheme}>
        <RouterProvider router={router} />
      </EuiThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export { App }
