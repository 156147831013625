import { type ChangeEvent, type MouseEventHandler } from 'react'

export type SelectableListItem = {
  label: string
  name: string
  isChecked: boolean
}

export enum SIDE {
  LEFT,
  RIGHT,
}

export type HandleMoveArgs = {
  doesMoveToChecked?: boolean
  doesMoveChecked?: boolean
}

export type SelectableListProps = {
  itemsList: SelectableListItem[]
  onChange: (items: SelectableListItem[]) => void
  isLoading?: boolean
}

export type SelectableListSideProps = {
  title?: string
  itemsList: SelectableListItem[]
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

type SelectableListAction = {
  isDisabled: boolean
  iconType: string
  ariaLabel: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export type SelectableListActionsProps = {
  actions: SelectableListAction[]
}
