import { EuiFlexGroup, EuiSpacer, EuiText } from '@elastic/eui'
import { MESSAGE } from '@shared/config'

import { useSelectableList } from '../model'
import { type SelectableListProps, SIDE } from '../types'
import { SelectableListActions } from './selectable-list-actions'
import { SelectableListSide } from './selectable-list-side'

const SelectableList: ReactFc<SelectableListProps> = ({
  itemsList,
  onChange,
  isLoading = false,
}) => {
  const { left, right, actions, handledToggle } = useSelectableList({
    itemsList,
    onChange,
    isLoading,
  })

  return (
    <>
      <EuiFlexGroup gutterSize="none">
        <SelectableListSide
          title={MESSAGE.AVAILABLE_ATTRIBUTES}
          onChange={handledToggle(SIDE.LEFT)}
          itemsList={left}
        />
        <SelectableListActions actions={actions} />
        <SelectableListSide
          title={MESSAGE.CHECKED_ATTRIBUTES}
          onChange={handledToggle(SIDE.RIGHT)}
          itemsList={right}
        />
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiText>{MESSAGE.EXPORT_TIME}</EuiText>
    </>
  )
}

export { SelectableList }
