import { hasCookie } from '@shared/lib'
import { type LoaderFunctionArgs, redirect } from 'react-router'

const privateLoader = ({ request }: LoaderFunctionArgs) => {
  const isAuthorized = hasCookie('csrftoken')

  if (!isAuthorized) {
    // Сохраняем адрес страницы и все параметры до редиректа на логин
    const url = new URL(request.url)
    const params = new URLSearchParams(url.search)
    params.set('from', url.pathname)

    return redirect('/login?' + params.toString())
  }

  return null
}

export { privateLoader }
