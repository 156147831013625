import { EuiButtonEmpty, EuiText } from '@elastic/eui'
import { useToggle } from '@shared/hooks'
import { useParams } from 'react-router'

import { ChangeUserPasswordModal } from './change-user-password-modal'

const ChangeUserPassword: ReactFc = () => {
  const { id } = useParams()
  const userId = parseInt(id!)

  const [isModalOpen, toggleIsModalOpen] = useToggle()

  return (
    <>
      <EuiText>
        Пароли хранятся в зашифрованном виде, поэтому нет возможности посмотреть пароль этого
        пользователя, но вы можете изменить его используя
        <EuiButtonEmpty onClick={toggleIsModalOpen} size="s">
          эту форму
        </EuiButtonEmpty>
      </EuiText>
      <ChangeUserPasswordModal isOpen={isModalOpen} onClose={toggleIsModalOpen} userId={userId} />
    </>
  )
}

export { ChangeUserPassword }
