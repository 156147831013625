import { EuiPageSection } from '@elastic/eui'
import { ATTRIBUTE_GROUPS_COLUMNS, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { RemoveAttributeGroup, UpdateAttributeGroup } from '@features/attributes'
import { TableContextMenu } from '@shared/ui/context-menu'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'
import { useSearchParams } from 'react-router'

export const AttributeGroupsTable: ReactFc = () => {
  const { sorting, sortList, onTableChange } = useSortTable<AttributeGroup>('order')
  const { selection } = useSelectionTable<AttributeGroup>()

  // Поиск по группам атрибутов
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') || undefined

  const { isLoading, data } = useAttributeGroupsList(search)

  const columnsWithAction = [
    {
      width: '40px',
      actions: [
        {
          render: (props: AttributeGroup) => {
            return (
              <TableContextMenu>
                <UpdateAttributeGroup id={props.id} />
                <RemoveAttributeGroup id={props.id} />
              </TableContextMenu>
            )
          },
        },
      ],
    },
    ...ATTRIBUTE_GROUPS_COLUMNS,
  ]

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={columnsWithAction}
        items={sortList(data)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}
