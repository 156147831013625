import { useSearchParams } from 'react-router'

import {
  type DeleteParam,
  type GetParamValues,
  type HasParam,
  type HasParamValue,
  type ParamAction,
  type UseRouterParamsReturn,
} from './types'

/**
 * Хук для установки параметров маршрутизатора.
 *
 * @returns { UseRouterParamsReturn } Набор функций для работы с параметрами маршрутизатора.
 *
 * hasParam – Проверка, есть ли параметр в адресной строке.
 *
 * setParam – Установка значения параметра.
 *
 * getParamValues – Получение значения параметра.
 *
 * deleteParam – Удаление параметра.
 *
 * addParamValue – Добавление значения параметра.
 *
 * removeParamValue – Удаление значения параметра.
 *
 * toggleParamValue – Переключение значения параметра.
 *
 * hasParamValue – Проверка, есть ли значение в параметре.
 *
 * getParamId – Возвращает id параметр в виде числа.
 */
export const useRouterParams = (): UseRouterParamsReturn => {
  const [searchParams, setSearchParams] = useSearchParams()

  const hasParam: HasParam = (param) => {
    return searchParams.has(param)
  }

  const setParam: ParamAction = (param, value) => {
    searchParams.set(param, value.toString())
    setSearchParams(searchParams)
  }

  const getParamValues: GetParamValues = (param) => {
    const value = searchParams.get(param)

    return value ? value.split(',') : []
  }

  const deleteParam: DeleteParam = (param) => {
    searchParams.delete(param)
    setSearchParams(searchParams)
  }

  const addParamValue: ParamAction = (param, value) => {
    const values = getParamValues(param)

    if (!values.includes(value.toString())) {
      setParam(param, [...values, value.toString()])
    }
  }

  const removeParamValue: ParamAction = (param, value) => {
    const values = getParamValues(param).filter((item) => {
      return item !== value.toString()
    })

    setParam(param, values)

    if (getParamValues(param).length === 0) {
      deleteParam(param)
    }
  }

  const toggleParamValue: ParamAction = (param, value) => {
    const values = getParamValues(param)

    if (values.includes(value.toString())) {
      removeParamValue(param, value.toString())
    } else {
      addParamValue(param, value)
    }
  }

  const hasParamValue: HasParamValue = (param, value) => {
    const values = getParamValues(param)

    return values.includes(value.toString())
  }

  const getParamId = () => {
    if (!hasParam('id')) {
      return null
    }

    return Number(getParamValues('id'))
  }

  const params = Object.fromEntries(searchParams)

  return {
    hasParam,
    setParam,
    deleteParam,
    getParamValues,
    addParamValue,
    removeParamValue,
    toggleParamValue,
    hasParamValue,
    getParamId,
    params,
  }
}
