import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPageSection } from '@elastic/eui'
import { SETTINGS_SKUS_COLUMNS, useSkuListApi } from '@entities/catalog'
import { type Sku } from '@entities/catalog/types'
import { ACTION } from '@shared/config'
import { Search } from '@shared/ui'
import { Table, useSortTable } from '@shared/ui/table'
import { useParams, useSearchParams } from 'react-router'

const CategorySkusTab: ReactFc = () => {
  const { id: categoryIdParam = '' } = useParams()
  const categoryId = parseInt(categoryIdParam)
  const { sorting, sortList, onTableChange } = useSortTable<Sku>()

  // Поиск по атрибутам
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') || undefined

  const { isLoading, data: skuList = [] } = useSkuListApi({
    categories: [categoryId],
    search,
  })

  return (
    <EuiPageSection paddingSize="none">
      <EuiFlexGroup alignItems="stretch">
        <EuiFlexItem grow={false}>
          <Search searchParam="search" placeholder={ACTION.FIND_SKU} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule margin="s" />

      <Table
        columns={SETTINGS_SKUS_COLUMNS}
        items={sortList(skuList)}
        sorting={sorting}
        onChange={onTableChange}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}

export { CategorySkusTab }
