import { number, route } from 'react-router-typesafe-routes'

import { APP_NAME } from './constans'

export const ROUTES = {
  CATALOG: route({
    path: 'catalog',
    children: {
      SKU: route({
        path: ':id',
        params: { id: number().defined() },
        children: {
          PRINT: route({ path: 'print' }),
        },
      }),
    },
  }),
  DAM: route({ path: 'dam' }), // Маршрут для DAM
  SETTINGS: route({
    path: 'settings',
    children: {
      ATTRIBUTE_GROUPS: route({
        path: 'attribute-groups',
        children: {
          GROUP: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      ATTRIBUTES: route({
        path: 'attributes',
        children: {
          ATTRIBUTE: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      VALIDATORS: route({
        path: 'validators',
        children: {
          VALIDATOR: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      USERS: route({
        path: 'users',
        children: {
          USER: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      CATEGORIZATION: route({
        path: 'categorization',
        children: {
          CATEGORY: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      CLUSTERING: route({ path: 'clustering' }),
      CURRENCIES_AND_PRICES: route({ path: 'currencies-and-prices' }),
      INFO_MODELS: route({ path: 'info-models' }),
      RELATED_PRODUCTS: route({ path: 'related-products' }),
      RULES: route({ path: 'rules' }),
      TAXONOMY: route({ path: 'taxonomy' }),
      TEMPLATES: route({ path: 'templates' }),
      UNITS: route({ path: 'units' }),
      VALUE_LISTS: route({ path: 'value-lists' }),
    },
  }),
  EXPORT: route({
    path: 'export',
    children: {
      FILES: route({ path: 'exported-files' }),
    },
  }),
  LOGIN: route({ path: 'login' }),
  LOGOUT: route({ path: 'logout' }),
  PERMISSION_ERROR: route({ path: 'permission-error' }),
  ERROR: route({ path: '*' }),
}

export const PAGE_TITLES = {
  SKUS: {
    title: 'Все товары',
    CATALOG: { title: 'Каталог' },
  },
  SETTINGS: {
    title: 'Настройки',
    ATTRIBUTES: { title: 'Атрибуты' },
    ATTRIBUTE_GROUPS: { title: 'Группы атрибутов' },
    CATEGORIZATION: { title: 'Категоризация' },
    CLUSTERING: { title: 'Кластеризация' },
    CURRENCIES_AND_PRICES: { title: 'Валюты и цены' },
    INFO_MODELS: { title: 'Инфомодели' },
    RELATED_PRODUCTS: { title: 'Связанные товары' },
    RULES: { title: 'Правила' },
    TAXONOMY: { title: 'Таксономия' },
    TEMPLATES: { title: 'Шаблоны' },
    UNITS: { title: 'Ед. измерений' },
    VALUE_LISTS: { title: 'Списки значений' },
    VALIDATORS: { title: 'Валидаторы' },
    VALIDATOR: { title: 'Валидатор' },
    USERS: { title: 'Пользователи' },
    USER: { title: 'Пользователь' },
  },
  EXPORT: {
    title: 'Экспорт',
    FILES: { title: 'Файлы' },
  },
  LOGIN: { title: 'Авторизация' },
  LOGOUT: { title: 'Выход из системы' },
  ERROR: { title: 'Страница не найдена' },
  PERMISSION_ERROR: { title: 'Недостаточно прав' },
  SUFFIX: ` | ${APP_NAME}`,
  LOADING: `…`,
}
