import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'
import { css } from '@emotion/react'
import { CheckboxInput } from '@shared/ui/form'
import { overflowAuto } from '@shared/ui/styles'

import { type SelectableListSideProps } from '../types'

const SelectableListSide: ReactFc<SelectableListSideProps> = ({ title, itemsList, onChange }) => {
  const maxHeight = css`
    max-height: 460px;
  `

  return (
    <EuiFlexItem grow={3}>
      <EuiText size="s">{`${title}${itemsList.length ? ` (${itemsList.length})` : ''}`}</EuiText>
      {title && <EuiSpacer size="s" />}
      <EuiPanel hasShadow={false} hasBorder css={[overflowAuto, maxHeight]}>
        <EuiFlexGroup
          direction="column"
          justifyContent="center"
          alignItems="center"
          gutterSize="none"
        >
          {itemsList.map(({ name, label, isChecked }) => {
            return (
              <CheckboxInput
                key={name}
                name={name}
                label={label}
                inputProps={{ onChange, checked: isChecked }}
              />
            )
          })}
        </EuiFlexGroup>
      </EuiPanel>
    </EuiFlexItem>
  )
}

export { SelectableListSide }
