import { createNewObject } from '@shared/lib'

import { API_BASE, DEFAULT_HEADERS, HTTP_METHOD } from './constans'
import { type FetchOptions, type FetchResponse } from './types'

export async function fetchWrapper<T, B = unknown>(
  endpoint: string,
  options: FetchOptions<B> = createNewObject(),
): Promise<FetchResponse<T>> {
  const { method = HTTP_METHOD.GET, headers = {}, body = null } = options

  try {
    const response = await fetch(`${API_BASE}${endpoint}`, {
      method,
      headers: { ...DEFAULT_HEADERS, ...headers },
      body: body ? JSON.stringify(body) : null,
    })

    const fetchResponse: FetchResponse<T> = {
      data: response.status !== 204 ? await response.json() : {},
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      ok: response.ok,
      redirected: response.redirected,
      url: response.url,
    }

    return fetchResponse
  } catch (error) {
    console.error('Fetch failed:', error) // Логирование ошибки
    throw new Error('Failed to fetch data') // Бросаем пользовательскую ошибку
  }
}
