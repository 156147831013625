import { EuiLink } from '@elastic/eui'

import { FILE_STATUS, type FilesCardNameProps } from './types'

const FilesCardName: ReactFc<FilesCardNameProps> = ({ file_url, status, file_name }) => {
  const isDisabled = status !== FILE_STATUS.COMPLETED_SUCCESS
  const linkProps = isDisabled ? { disabled: true } : { href: file_url, target: '_blank' }

  return <EuiLink {...linkProps}>{file_name}</EuiLink>
}

export { FilesCardName }
