import { $api, ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { ROUTES } from '@shared/config'

import { type Sku, type SkuListQuery } from '../types'

export const useSkuListApi = (args: SkuListQuery = {}) => {
  // TODO: Пора добавлять пагинацию
  const skuList = $api.useQuery('get', '/skus/', {
    params: {
      query: {
        size: 100,
        ...args,
      },
    },
  })

  const data: Sku[] = skuList.data?.results.map((sku) => {
    const href = ROUTES.CATALOG.SKU.$buildPath({ params: { id: sku.id } })
    const name = extractAttribute(sku, ATTRIBUTE_FLAGS.NAME)?.value
    const cid = extractAttribute(sku, ATTRIBUTE_FLAGS.SKU)?.value

    const attributes = Object.fromEntries(
      sku.attributes.map((attribute) => {
        return [attribute.slug, attribute.value]
      }),
    )

    return {
      ...sku,
      ...attributes,
      href,
      name,
      cid,
    }
  }) as Sku[]

  return {
    ...skuList,
    data,
  }
}
