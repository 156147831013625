import { EuiFlexGroup, EuiPanel } from '@elastic/eui'
import { useFilesListApi } from '@entities/export-files'
import { MESSAGE } from '@shared/config'

import { ExportFilesCard } from './export-files-card'
import { ExportFilesListSkeleton } from './export-files-list-skeleton'

const ExportFilesList: ReactFc = () => {
  const { data, isLoading } = useFilesListApi()
  const files = data?.results

  if (isLoading) {
    return <ExportFilesListSkeleton />
  }

  return (
    <EuiFlexGroup direction="column" gutterSize="s">
      {files?.map((file) => {
        return <ExportFilesCard key={file.uuid} {...file} />
      })}

      {!files?.length && (
        <EuiPanel hasShadow={false}>
          <EuiFlexGroup justifyContent="center">{MESSAGE.EMPTY_EXPORT_FILES}</EuiFlexGroup>
        </EuiPanel>
      )}
    </EuiFlexGroup>
  )
}

export { ExportFilesList }
