import { EuiPageSection } from '@elastic/eui'
import { useCategoriesColumns, useCategoriesList } from '@entities/categories'
import { type Category } from '@entities/categories/types'
import { RemoveCategory, UpdateCategory } from '@features/categories'
import { TableContextMenu } from '@shared/ui/context-menu'
import { Table, useSortTable } from '@shared/ui/table'
import { useSearchParams } from 'react-router'

const CategorizationTable: ReactFc = () => {
  const columns = useCategoriesColumns()
  const { sorting, sortList, onTableChange } = useSortTable<Category>('id')

  // Поиск по атрибутам
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') || undefined

  const { isLoading, data = [] } = useCategoriesList({ search: search })

  const columnsWithAction = [
    {
      width: '40px',
      actions: [
        {
          render: (props: Category) => {
            return (
              <TableContextMenu>
                <UpdateCategory id={props.id} />
                <RemoveCategory id={props.id} />
              </TableContextMenu>
            )
          },
        },
      ],
    },
    ...columns,
  ]

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={columnsWithAction}
        items={sortList(data)}
        sorting={sorting}
        onChange={onTableChange}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}

export { CategorizationTable }
