import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { CATALOG_CARD_IMAGE_SIZE } from '@shared/config'
import { Image } from '@shared/ui/image'
import { Link } from 'react-router'

import { type SkuCardImageProps } from './types'

export const SkuCardImage: ReactFc<SkuCardImageProps> = (props) => {
  const { href, preview, name } = props

  return (
    <EuiFlexGroup justifyContent="spaceAround">
      <EuiFlexItem grow={false}>
        <Link to={href}>
          <Image
            src={preview}
            width={CATALOG_CARD_IMAGE_SIZE}
            height={CATALOG_CARD_IMAGE_SIZE}
            alt={name}
          />
        </Link>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
