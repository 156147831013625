import { EuiPageSection } from '@elastic/eui'
import { useCatalogColumns } from '@entities/catalog'
import { type Sku } from '@entities/catalog/types'
import { Table, useSortTable } from '@shared/ui/table'
import { useNavigate } from 'react-router'

import { type CatalogTableProps } from './types'

export const CatalogTable: ReactFc<CatalogTableProps> = ({ skuList, isLoading }) => {
  const columns = useCatalogColumns()
  const { sorting, sortList, onTableChange } = useSortTable<Sku>()
  const navigate = useNavigate()

  const getRowProps = ({ href }: Sku) => {
    return {
      onClick: () => {
        navigate(href)
      },
    }
  }

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={columns}
        items={sortList(skuList)}
        sorting={sorting}
        onChange={onTableChange}
        rowProps={getRowProps}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}
