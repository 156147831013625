import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'

import { type GetStatusProps } from './types'

const FilesCardStatus: ReactFc<GetStatusProps> = ({ type, color, text }) => {
  return (
    <EuiFlexGroup justifyContent="flexEnd" alignItems="center" gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiIcon type={type} size="m" color={color} />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiText size="m">{text}</EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export { FilesCardStatus }
