import { type EuiFilePickerProps } from '@elastic/eui'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { $files } from '@shared/api/openapi-client'
import { useRef } from 'react'
import { useParams } from 'react-router'

import { type UploadFileToAttributeValueArgs } from './types'

const useUploadAttributeValue = () => {
  const { id: objectIdParam = '' } = useParams()
  const objectId = parseInt(objectIdParam)
  const filePickerRef = useRef<Omit<EuiFilePickerProps, 'stylesMemoizer'>>(null)

  const uploadFile = $files.useMutation('post', '/backend/api/files/v1/uploads/')

  const onSuccess = () => {
    // @ts-ignore Подробнее на https://eui.elastic.co/#/forms/form-controls#file-picker
    filePickerRef.current?.removeFiles()

    queryClient.refetchQueries({
      queryKey: API_QUERY_KEY.SKU(objectId),
    })
  }

  const uploadFileToAttributeValue = async ({
    attribute,
    files,
  }: UploadFileToAttributeValueArgs) => {
    // Проверяем, есть ли файлы для загрузки
    if (files && files.length > 0) {
      // Преобразуем FileList в массив
      Array.from(files).forEach((file) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('sku_id', objectIdParam)
        formData.append('attribute_id', attribute.id.toString())

        uploadFile.mutate(
          {
            // @ts-ignore TODO: типы мутаций
            body: formData,
          },
          { onSuccess },
        )
      })
    }
  }

  return {
    filePickerRef,
    uploadFileToAttributeValue,
    isFileSubmitting: uploadFile.isPending,
    onSuccess,
  }
}

export { useUploadAttributeValue }
