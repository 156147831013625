import { EuiBreadcrumbs, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { useStyles } from '@shared/ui/styles'
import { useMatches, useNavigate } from 'react-router'

import { BackLink } from './back-link'
import { DynamicTitle } from './dynamic-title'
import { getStyles } from './styles'
import { type BreadcrumbMatches } from './types'

export const Breadcrumbs: ReactFc = () => {
  const styles = useStyles(getStyles)
  const matches = useMatches() as BreadcrumbMatches
  const navigate = useNavigate()

  const breadcrumbs = matches
    .filter((match) => {
      return match.handle?.hasDynamicTitle || match.handle?.crumb
    })
    .map((match) => {
      const title = match.handle?.hasDynamicTitle ? <DynamicTitle /> : match.handle?.crumb

      return {
        text: title,
        onClick: () => {
          navigate(match.pathname)
        },
      }
    })
    .slice(-2)

  return (
    <EuiHeaderSection css={styles.breadcrumbs}>
      <BackLink matches={matches} />
      <EuiHeaderSectionItem>
        <EuiBreadcrumbs breadcrumbs={breadcrumbs} lastBreadcrumbIsCurrentPage />
      </EuiHeaderSectionItem>
    </EuiHeaderSection>
  )
}
