import { HTTP_METHOD } from '../constans'
import { fetchWrapper } from '../fetch-wrapper'
import { type FetchOptions } from '../types'

export const buildRequestApi = <T, P = unknown>(
  url: string,
  errorMessage: string,
  options?: FetchOptions<P>,
) => {
  return async (bodyParams?: P) => {
    /**
     * TODO: Так как вызывается из React query пробрасывается окружение, нужно добавить обработку signals
     * Сейчас соединяет, если есть body
     */
    let combinedBody = options?.body

    if (bodyParams && !Object.prototype.hasOwnProperty.call(bodyParams, 'queryKey')) {
      combinedBody = {
        ...combinedBody,
        ...bodyParams,
      }
    }

    const searchParams = options?.query ? new URLSearchParams(options.query) : ''

    const response = await fetchWrapper<T>(`${url}?${searchParams}`, {
      method: options?.method || HTTP_METHOD.GET,
      body: combinedBody,
    })

    if (response.status >= 200 && response.status < 300) {
      return response.data.results || (response.data as T)
    }

    return Promise.reject(new Error(errorMessage)) as Promise<never>
  }
}
