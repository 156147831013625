import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { ExportFilesList } from '@widgets/export'

const FilesPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.EXPORT.FILES.title })

  return (
    <Layout pageBodyProps={{ paddingSize: 's' }}>
      <ExportFilesList />
    </Layout>
  )
}

export { FilesPage }
